<template>
  <v-container fluid>
    <v-row justify="end" class="mb-4">
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          :placeholder="$_strings.invoice.SEARCHNO"
          ref="search"
          @keyup.enter="setSearch"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet rounded>
      <v-row>
        <v-col class="pa-0">
          <FilterInvoice
            :filters="filters"
            :showFilterBy="true"
            @fetchInvoice="fetchInvoice"
          />
        </v-col>
        <v-col
          cols="auto"
          class="ma-4 mr-6"
          v-if="userAccess.canCreate"
        >
          <v-btn
            color="primary"
            elevation="1"
            small
            @click="$refs.DialogCreateInvoice.dialog = true"
          >
            {{$_strings.invoice.TITLE_CREATE}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :loading="isLoading"
            :headers="headers"
            :items="itemsInvoice"
            item-key="invoiceNo"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            @click:row="rowClicked"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.invoiceNo`]=items>
              <p class="ma-0">{{ items.item.invoiceNo }}</p>
              <span class="caption">{{ dateFormat(items.item.invoiceDate) }}</span>
            </template>
            <template v-slot:[`item.invoiceGroup`]={item}>
              <p class="ma-0">{{ renamePeriode(item.invoiceGroup) }}</p>
              <span class="caption">{{ dateFormat(item.periodStart) }} s/d {{ dateFormat(item.periodEnd) }}</span>
            </template>
            <template v-slot:[`item.lokasiAsal`]=items>
              <span>{{ items.item.lokasiAsal ? items.item.lokasiAsal : '-' }}</span>
            </template>
            <template v-slot:[`item.lokasiTujuan`]=items>
              <span>{{ items.item.lokasiTujuan ? items.item.lokasiTujuan : '-' }}</span>
            </template>
            <template v-slot:[`item.totalAmount`]=items>
              <p class="ma-0">Rp. {{ formatAmount(items.item.totalAmount) }}</p>
              <span class="caption" v-if="items.item.invoiceType === 'Pengiriman'">{{ items.item.totalShipment }} Pesanan</span>
              <span class="caption" v-else>{{ items.item.totalShipment }} Biaya</span>
            </template>
            <template v-slot:[`item.invoiceType`]=items>
              <span>{{ items.item.invoiceType }}</span>
            </template>
            <template v-slot:[`item.createdBy`]=items>
              <p class="ma-0">{{ items.item.createdBy }}</p>
              <span class="caption">{{ dateFormat(items.item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.status`]="{item}">
              <div v-if="item.isLoading" class="d-flex justify-center">
                <v-progress-circular
                  size="25"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <v-select
                v-else
                class="mt-3 pa-0 text-caption"
                style="width: 10rem"
                outlined
                dense
                :items="itemsStatus(item)"
                item-value="text"
                item-text="text"
                :value="item.status"
                @change="($event) => changeStatus($event, item)"
                @click.stop.prevent
              >
              </v-select>
            </template>
            <template v-slot:[`item.actionColumn`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon x-small class="mr-2" color="primary" @click="exportPdf(item)" @click.stop.prevent>
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>File PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.status === 'Proforma'" v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="exportExcel(item)" @click.stop.prevent>
                    <v-icon> mdi-file-excel-box </v-icon>
                  </v-btn>
                </template>
                <span>File XLS</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.status === 'Proforma'" v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="modalEmail(item)" @click.stop.prevent >
                    <v-icon> mdi-email </v-icon>
                  </v-btn>
                </template>
                <span>Email</span>
              </v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card>
          <v-card-title>
            <span class="headtitle-email">Masukan Email Tujuan</span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-subheader>Email</v-subheader>
                </v-col>
                <v-col
                  cols="10"
                  class="mr-4"
                >
                  <v-autocomplete
                    outlined
                    dense
                    :items="getEmail"
                    :search-input.sync="searchEmail"
                    item-value="email"
                    item-text="email"
                    v-model="form.email"
                    multiple
                    chips
                    persistent-hint
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeEmail(item)"
                      >
                        {{ item.email }}<br>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{item.email}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal"
                >
                  {{$_strings.common.CANCEL}}
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  @click="submitEmail"
                >
                  {{$_strings.common.SAVE}}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogCreateInvoice
      ref="DialogCreateInvoice"
      @fetchInvoice="fetchInvoice"
    />
    <DialogDateReceived
      ref="DialogDateReceived"
      :invoiceDate="periodStart"
      @updateStatusInvoice="updateStatusInvoice"
      @fetchInvoice="fetchInvoice"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import FilterInvoice from './FilterInvoice.vue';
import DialogCreateInvoice from './Dialog/CreateInvoice.vue';
import DialogDateReceived from './Dialog/DateReceived.vue';
import { thousandSeparated, renamePeriode, dateFormat } from '../../helper/commonHelpers';

export default {
  name: 'InvoiceFixedPriceList',
  components: {
    FilterInvoice,
    DialogCreateInvoice,
    DialogDateReceived,
  },
  created() {
    this.getEmailList();
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'invoiceGroup',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.invoice.INVOICE_TOTAL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'actionColumn',
          align: 'left',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '8vw',
        },
      ],
      itemsInvoice: [],
      search: this.$route.query.search || '',
      filters: {
        invoiceNo: '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
        statusInvoice: this.$route.query.statusInvoice ? this.$route.query.statusInvoice.split(',') : [],
        typeInvoice: this.$route.query.typeInvoice ? this.$route.query.typeInvoice.split(',') : [],
        billedToCompanyId: '',
      },
      invoiceListTotalEntry: 0,
      pagination: {
        itemsPerPage: +this.$route.query.itemsPerPage || 10,
        page: +this.$route.query.page || 1,
        sortBy: [],
        sortDesc: [],
      },
      form: {
        email: [],
      },
      valid: false,
      dialog: false,
      getEmail: [],
      searchEmail: null,
      statusChange: '',
      invoiceId: '',
      invoiceStatusId: '',
      periodStart: '',
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        const { page, itemsPerPage } = this.$route.query;
        if ((newVal.page !== +page) || (newVal.itemsPerPage !== +itemsPerPage)) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: newVal.page,
              itemsPerPage: newVal.itemsPerPage,
            },
          });
        }
        this.fetchInvoice();
      },
      deep: true,
    },
    'filters.dateFrom': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: newVal,
          dateTo: this.filters.dateTo,
        },
      });
    },
    'filters.dateTo': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: this.filters.dateFrom,
          dateTo: newVal,
        },
      });
    },
    searchEmail(val) {
      if (val === null) {
        this.$_services.invoice.emailList()
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      } else {
        this.$_services.invoice.emailSearchList(val)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      }
    },
  },
  methods: {
    dateFormat,
    rowClicked(item) {
      if (item.invoiceType === 'Pengiriman') {
        this.$router.push({
          name: 'invoice-list-detail-fixed-price',
          params: {
            invoiceId: item.id,
          },
        });
      } else if (item.status === 'Proforma') {
        const params = {
          invoiceId: item.id,
        };
        this.$router.push({
          name: 'invoice-edit',
          params,
        });
      }
    },
    setSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
          },
        });
      }
      this.filters.invoiceNo = this.search;
      this.fetchInvoice();
    },
    async fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
      };
      Object.keys(this.filters).forEach((filter) => {
        if (typeof this.filters[filter] === 'object'
        && this.filters[filter] !== null
        && this.filters[filter].length) {
          filters[filter] = `qin(${this.filters[filter].toString()})`;
          return;
        }
        if (this.filters[filter] && filter === 'invoiceNo') {
          filters[filter] = `qlike(${this.filters[filter]})`;
          return;
        }
        if (this.filters[filter]) {
          filters[filter] = this.filters[filter];
        }
      });
      if (sortBy.length) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      try {
        this.isLoading = true;
        const result = await this.$_services.invoice.listFixedPriceInvoice(filters);
        this.itemsInvoice = result.data.contents;
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    changeStatus(event, itemStatus) {
      this.periodStart = dayjs(itemStatus.periodStart).format('YYYY-MM-DD');
      this.statusChange = this.$_strings.invoice.FILTER_STATUS_LIST.find((i) => i.text === event).value;
      this.invoiceStatusId = itemStatus.id;
      if (event === 'Sudah diterima' || event === 'Sudah dibayar') {
        this.$refs.DialogDateReceived.dialog = true;
        return;
      }
      this.updateStatusInvoice();
    },
    updateStatusInvoice(date = '') {
      this.$root.$loading.show();
      this.$_services.invoice.changeStatusInvoice(this.invoiceStatusId, this.statusChange, date)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_STATUS_MESSAGE_TEXT);
        }).finally(() => {
          this.$root.$loading.hide();
          this.fetchInvoice();
        });
    },
    itemsStatus(itemStatus) {
      const findIndex = this.$_strings.invoice.FILTER_STATUS_LIST.findIndex((e) => e.text === itemStatus.status);
      const itemList = [];
      this.$_strings.invoice.FILTER_STATUS_LIST.forEach((item, index) => {
        if (index === findIndex || index === findIndex + 1) {
          itemList.push({
            ...item,
          });
          return;
        }
        itemList.push({
          ...item,
          disabled: true,
        });
      });
      return itemList;
    },
    renamePeriode,
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    exportPdf(item) {
      if (item.invoiceNo) {
        this.$_services.invoice.exportPdfInvoice(item.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const { invoiceNo } = item;
          const invoiceLink = invoiceNo.replace(/\//g, '_');
          link.download = `invoice-${invoiceLink}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
        return;
      }
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'PDF',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-fixed-price-proforma.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    exportExcel(item) {
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'EXCEL',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/xls' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-fixed-price-proforma.xls';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    getEmailList() {
      this.$_services.invoice.emailList()
        .then((res) => {
          this.getEmail = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    modalEmail(item) {
      this.dialog = true;
      this.invoiceId = item.id;
    },
    closeModal() {
      this.dialog = false;
      this.invoiceId = '';
    },
    removeEmail(item) {
      this.form.email.splice(this.form.email.indexOf(item), 1);
      this.form.email = [...this.form.email];
    },
    submitEmail() {
      if (this.form.email.length < 1) {
        this.$dialog.notify.error('Mohon periksa kembali field yang kosong');
        return;
      }
      this.$root.$loading.show();
      this.$_services.invoice.sendEmail(this.form.email, this.invoiceId)
        .then((res) => {
          this.$dialog.notify.success('Berhasil mengirimkan proforma ke email.');
        }).finally(() => {
          this.$root.$loading.hide();
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchInvoice();
        });
    },
  },
};
</script>
